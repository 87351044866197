import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserManagementService {

  readonly api = 'https://us-central1-' + environment.firebaseConfig.projectId + '.cloudfunctions.net/app';
  // readonly api = 'http://127.0.0.1:5001/hospappwebsite/us-central1/app'

  constructor(public afs: AngularFirestore,
              public router: Router,
              public location: Location,
              public http: HttpClient) { }

  async deleteUserHandler(userObject: any) {
    if (confirm("Are you sure") == true) {
      if (!userObject.noAuth) {
        await this.deleteUser(userObject.uid);
      } 
      try {
        await this.deleteData(userObject.uid);
      } catch(err) {
      }
      this.location.back();
    } else {
      console.log('cancelled')
    }
  }
  deleteUser(uid: string) {
    return new Promise((resolve, reject) => {
      const url = `${this.api}/firebase/user/delete`;
      let sub = this.http.post<any>(url, {uid: uid}).subscribe(data => {
        sub.unsubscribe();
        resolve(null);
      }, err => {
        reject(err)
      });
    })
  }
  async deleteData(uid: string) {
    this.afs.doc('users/' + uid).delete();
    this.afs.doc('users/' + uid + '/customer/customer').delete();
    this.afs.doc('users/' + uid + '/profile/profile').delete();
    this.afs.doc('users/' + uid + '/appstoreDetails/appstoreDetails').delete();
    this.afs.doc('users/' + uid + '/starterTheme/starterTheme').delete();
    this.afs.doc('users/' + uid + '/usage/totals').delete();
    this.afs.doc('users/' + uid + '/config/config').delete();
    this.afs.doc('users/' + uid + '/config/display').delete();
    this.afs.doc('users/' + uid + '/config/service').delete();
    this.afs.doc('users/' + uid + '/password/password').delete()
  }

  scheduleEmail(sid: string, uid: string, agentUid: string) {
    console.log('from scheduleEmail', sid, uid, agentUid)
    return new Promise((resolve, reject) => {
      const url = `${this.api}/add/sequence`;
      let sub = this.http.post(url,{
        sid:sid,
        uid:uid,
        agentUid:agentUid
      }).subscribe(data => {
        sub.unsubscribe();
        resolve(null);
      }, err => {
        reject(err)
      });
    });
  }

  addEmailSender(senderId: string, agencyId: string) {
    return new Promise((resolve, reject) => {
      const url = `${this.api}/sengrid/senders/add`;
      let sub = this.http.post(url,{
        senderId:senderId,
        agencyId:agencyId,
      }).subscribe(data => {
        sub.unsubscribe();
        resolve(null);
      }, err => {
        reject(err)
      });
    });
  }
  deleteSender(senderId: string, agencyId: string) {
    return new Promise((resolve, reject) => {
      const url = `${this.api}/sengrid/senders/delete`;
      let sub = this.http.post(url,{
        senderId:senderId,
        agencyId:agencyId,
      }).subscribe(data => {
        sub.unsubscribe();
        resolve(null);
      }, err => {
        reject(err)
      });
    });
  }
  testEmail(senderId: string, agencyId: string, testEmailAddress: string) {
    return new Promise((resolve, reject) => {
      const url = `${this.api}/sengrid/senders/test`;
      let sub = this.http.post(url,{
        senderId:senderId,
        agencyId:agencyId,
        testEmailAddress:testEmailAddress
      }).subscribe(data => {
        sub.unsubscribe();
        resolve(null);
      }, err => {
        reject(err)
      });
    });
  }

  addSequenceToAgencySegment(aid: string, sid: string, arid: string) {
    return new Promise((resolve, reject) => {
      const url = `${this.api}/autoresponders/add`;
      let sub = this.http.post(url,{
        aid: aid,
        sid: sid,
        arid: arid
      }).subscribe(data => {
        console.log(data);
        sub.unsubscribe();
        resolve(null);
      }, err => {
        console.error(err);
        reject(err)
      });
    });
  }
}
