import { Component, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import * as AOS from 'aos';
import { AnalyticsService } from './services/analytics.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Platfio';

  constructor(public analytics: AnalyticsService,
    public updates: SwUpdate) {
      this.checkForUpdates()
  }
  checkForUpdates() {
    if (window.location.hostname !== 'localhost') {
      // Do not execute the method
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistrations().then(registrations => {
          if (registrations.length) {
            this.updates.versionUpdates.subscribe((event) => {
              if (event.type === "VERSION_READY") {
                document.location.reload();
              }
            })
          } else {
            console.log('No service worker is registered.');
          }
        });
      }
      return;
    }
  }
  ngOnInit(): void {
    AOS.init();
  }
}
